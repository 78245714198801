// structural styles for Angular Material dialog
@use '@angular/cdk' as cdk;
@include cdk.overlay();

@import 'variables.scss';

:root {
	--menu-width: 0;
}

html {
	margin: 0;
    height: 100%;
}

body {
    font-family: $font;
    font-size: $fontSize;
	margin: 0;
	padding: 0;
    background-color: $contentBackground;
    height: 100%;
    color: $text;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ui-widget {
	font-family: $font;
	font-size: $fontSize;
}

a {
    color: $link;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover {
        color: $hover;
    }

    &.mdi {
        color: $actionIcon;
    }
}

button.mdi {
    color: $actionIcon;
    width: 25px;
    height: 24px;
    padding: 0;
}

.toolbar {
    background-color: $toolbar;
    color: #ffffff;
    padding-left: $contentMargin;
    padding-right: $contentMargin;
    box-shadow: 0 5px 4px -2px rgba(158, 158, 158, 0.3);
    line-height: $toolbarHeight;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    > * {
        vertical-align: middle;
    }

    > .tab:first-child {
        margin-left: -14px;
    }

    .tab {
        display: inline-block;
        padding: 0 15px 0 15px;
        background-color: $tab;
        color: $tabText;
        margin-right: 16px;
        outline: none;
        text-transform: uppercase;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        &:hover {
            color: $tabActive;
        }

        &.active {
            color: $tabActive;
            border-bottom: 3px solid $tabActive;
        }

        &.invalid {
            border-bottom: 3px solid red;
        }
    }

    label, .field, filters {
        min-height: $toolbarHeight;
        line-height: $toolbarHeight - 4;
    }

    h3 {
        display: inline-block;
        margin: 0 16px 0 0;
        padding: 0;
        min-height: $toolbarHeight;
        line-height: $toolbarHeight - 4;
    }
}

.items {
    .item {
        background-color: $menuActiveBg;
        display: block;
        margin: 3px;
        border: 2px solid transparent;
        border-radius: 3px;
        color: #ffffff;

        > * {
            vertical-align: middle;
            margin: 3px 5px;
        }

        .mdi {
            font-size: 20px;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}

@media screen {
	.container {
		width: 100%;
		height: 100%;
		overflow: hidden;

		.header {
			height: $headerHeight;
			padding-left: 15px;
			background-color: $header;

			.userLogo {
				width: 173px;
				height: $headerHeight - 1;
				float: left;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin-right: 10px;
			}

			.busyIndicator {
				display: block;
				float: right;
				margin: 5px 20px 0 0;
				height: $headerHeight - 11px;
				width: $headerHeight - 11px;
				animation: rotate 1s infinite linear;
				border: 6px solid #fff;
				border-right-color: transparent;
				border-radius: 50%;
			}

			h1 {
				margin: 0;
				float: left;
				line-height: 20px;
				font-size: 26px;
				padding-top: 19px;
				padding-left: 0;
				font-weight: lighter;
				color: $headerText;
			}

			.currentUser {
				float: right;
				line-height: 20px;
				font-size: 17px;
				padding-top: 10px;
				padding-right: 20px;
				color: $headerText;
				text-align: right;
			}

			.logo {
				width: 215px;
				height: $headerHeight - 1;
				float: right;
				background-image: url('/images/sureWx_logo.png');
				background-repeat: no-repeat;
				background-position: center;
				margin-right: 10px;
			}

			.toggleMenu {
				float: left;
				border-style: none;
				border-radius: 0;
				background-color: transparent;
				color: #cccccc;
				font-size: 32px;
				padding: 5px 10px 3px 10px;
				border: 1px solid transparent;
				border-radius: 3px;
				margin: 6px 2px 0 -12px;
				cursor: pointer;

				&:hover {
					color: #ffffff;
					border-color: #ffffff;
				}
			}
		}

		> .content {
			position: absolute;
			top: $headerHeight;
			bottom: 0;
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.menuContainer {
				flex: 0 0 var(--menu-width);
				overflow: auto;
				background-color: $menu;
				z-index: 1;
				font-size: 17px;
				transition: flex .3s;

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						line-height: 20px;

						h3 {
							margin: 0;
							padding: 5px 3px 3px 17px;
							font-size: 14px;
							line-height: 24px;
							font-weight: normal;
							color: #eeeeee;
						}

						&:not(:first-child) h3 {
							border-top: 1px solid #2a6496;
						}

						ul {
							margin: 5px 0 12px 0;
							padding: 0;

							li h3 {
								margin-top: 12px;
								padding: 3px 1px 1px 27px;
							}

							ul a {
								padding: 4px 10px 4px 28px;
							}
						}

						a {
							color: $menuLink;
							padding: 4px 10px 4px 17px;
							display: block;

							&:hover {
								color: $menuHover;
							}

							&.active {
								color: $menuActive;
								background-color: $menuActiveBg;
							}
						}
					}
				}
			}

			.main {
				flex: 1 1 auto;
				overflow: auto;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;

				.contentWrapper {
					margin: $contentMargin;
					flex: 1 1 auto;
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;

					.contentMargins {
						margin: $contentMargin;
					}

					.noMargins {
						position: fixed;
						top: $headerHeight;
						left: var(--menu-width);
						right: 0;
						bottom: 0;
						overflow-y: auto;
						flex: 1 1 auto;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						transition: left .3s;
					}
				}
			}
		}
	}
}

.flexTab, .flexDialog {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding-bottom: 0;

	.options {
		margin-top: 10px;
		margin-bottom: 10px;

		label {
			min-height: 40px;

			&.inline {
				margin-right: 16px;
			}
		}

		.search {
			.mdi-magnify {
				padding: 4px 0 0 5px;
				color: #aaaaaa;
				height: 28px;
				font-size: 26px;
				line-height: 1;
				display: inline-block;
				vertical-align: middle;
				margin-top: -8px;
				position: relative;
			}

			input {
				margin-left: -35px;
				padding: 5px 5px 5px 35px;
				border: 1px solid #cccccc;
				border-radius: 4px;
			}
		}
	}

	.tabContent {
		overflow: auto;
		position: relative;

        &grid, &config-grid {
            overflow: hidden;
        }

		.contentMargins {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}

.flexDialog .ui-dialog-content {
	margin: 0;

	> ng-component {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

/* Hide/show elements without removing them from DOM */
[hidden] {
	display: none !important;
}

.errorPopup {
    .ui-dialog-titlebar {
        background: #d66;
        color: #fff;
    }
}
